<!--
 * @Description:
 * @Author: laicheng
 * @Date: 2022-10-14 11:31:31
-->
<template>
  <div class="container">
    <img class="image" src="https://oss.meijinguanjia.com/webview/combo/1.png" />
    <template v-for="item in listRef" :key="item.id">
      <div class="box" @click="goToItem(item.id)">
        <div class="line-wrap">
          <img :src="item.icon" alt="" class="left-img">
          <div class="right">
            <span class="title">{{item.title}}</span>
            <div class="price-box">
              <span class="price">{{item.price}}</span>
              <span class="suffix">元/套</span>
            </div>
            <div class="btn">立即购买</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { fetchComboItems } from '@/API/combo'
import { onMounted, ref } from 'vue'
const listRef = ref([])
onMounted(async () => {
  const result = await fetchComboItems({ id: 6 })
  listRef.value = result
  console.log({ result })
})
function goToItem(id) {
  wx.miniProgram.navigateTo({ url: `/pages/item/item?id=${id}` })
}
</script>
<style lang="scss"  scoped>
.container {
  min-height: 100vh;
  background: url("https://oss.meijinguanjia.com/webview/combo/2.png") repeat;
  padding-bottom: 100px;

  .image {
    width: 100%;
  }

  .box {

    margin-left: 30px;
    width: 690px;
    margin-bottom: 30px;
    background-color: #fff;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .line-wrap {
      display: flex;
      padding: 30px;
      box-sizing: border-box;
      border: 1px solid #397626;
      border-radius: 4px;

      .left-img {
        width: 190px;
        background-color: #ebf9fa;
        border-radius: 6px;
        height: 190px;
      }

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        align-items: center;

        .title {
          font-size: 32px;
          color: #000;
        }

        .price-box {
          .price {
            font-family: Din;
            font-size: 48px;
            color: #e5a425
          }

          .suffix {
            font-size: 28px;

          }
        }

        .btn {
          font-size: 30px;
          padding: 6px 24px;
          background-color: #397626;
          color: #fff;
          border-radius: 60px;
          font-weight: normal;
          margin-top: 30px;
        }
      }
    }

  }
}
</style>
